<template>
    <el-form ref="loginForm" :model="loginForm" label-width="80px" class="login-form">
        <h3></h3>
        <el-form-item label="username">
            <el-input v-model="loginForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="password">
            <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="handleLogin">LOGIN</el-button>
<!--            <el-button @click="handleReset">重置</el-button>-->
        </el-form-item>
    </el-form>
</template>

<script>
import apiRequest from '../utils/api-request'

export default {
  mixins: [apiRequest],
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    handleLogin() {
      const username = this.loginForm.username
      const password = this.loginForm.password
      if (username === '') {
        alert('account cannot be empty')
        return
      }
      if (password === '') {
        alert('password cannot be empty')
        return
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const params = {
            username: username,
            password: password
          }
          this.apiLogin(params).then((response) => {
            if (!response.success) {
              alert(response.msg)
            } else {
              // 假设后端返回的token是存储在response.data.token中
              const token = response.data.token
              const mikangyunAccount = response.data.mikangyunAccount
              const mikangyunPassword = response.data.mikangyunPassword
              const userType = response.data.userType
              localStorage.setItem('userToken', token)
              localStorage.setItem('mikangyunAccount', mikangyunAccount)
              localStorage.setItem('mikangyunPassword', mikangyunPassword)
              localStorage.setItem('userType', userType)
              if (userType === '1') {
                this.$router.push({ name: 'home' })
              } else if (userType === '2') {
                this.$router.push({ name: 'remind' })
              } else {
                this.$router.push({ name: 'telephoneNotHandle' })
              }
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
    // ,
    // handleReset() {
    //   this.$refs.loginForm.resetFields()
    // }
  }
}
</script>

<style scoped>
    .login-form {
        width: 300px;
        margin: 100px auto;
        padding: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
</style>
